import React, { useEffect } from 'react'
import {
	initializeSentry,
	isServerRendering,
	setAdminDestination,
	setAffiliate,
	setApiKeyStore,
	setIsHostSignupDomain,
	setIsIos,
	setIsRootDomain,
	setIsWebview,
	setManifestLoaded,
	setRootDomainStartPageData,
	setTiers,
} from './utils/applicationHelper'
import ScreenSizeProvider from './contexts/screen_size/ScreenSizeProvider'
import { cacheExchange, createClient, fetchExchange, Provider } from 'urql'
import initializeI18n from './components/reusable_components/middleware/NewExportedI18nextProvider'
import UserProvider from './contexts/user/UserProvider'
import GroupInquiryProvider from './contexts/group_inquiry/GroupInquiryProvider'
import { setRoutes } from './hooks/useRoutes'
import { setLocation } from './hooks/useLocation'
import getCsrfToken, { setCsrfToken } from './hooks/getCsrfToken'
import { setHostBranding } from './hooks/getHostBranding'
// import { setHelpScoutAppIds } from './utils/helpScoutHelper'

//install Graphcache?

initializeSentry()

const App = props => {
	const {
		locale,
		user,
		routes,
		children,
		railsContext,
		hostBranding,
		helpScoutAppIds,
		googleMapsSdkApiKey,
		adminDestination,
		stripePublishableKey,
		manifestLoaded,
		marketplace,
		affiliate,
		isRootDomain,
		isHostSignupDomain,
		isWebview,
		isIos,
		tiers,
	} = props
	setRoutes(routes)
	setLocation(railsContext)
	setHostBranding({
		active: hostBranding?.enabled && (hostBranding?.logo || hostBranding?.title),
		icon: hostBranding?.logo,
		title: hostBranding?.title,
	})
	setCsrfToken()
	setManifestLoaded(manifestLoaded)
	setRootDomainStartPageData(marketplace)
	setIsRootDomain(isRootDomain)
	setIsHostSignupDomain(isHostSignupDomain)
	setAffiliate(affiliate)
	setIsWebview(isWebview)
	setIsIos(isIos)
	setTiers(tiers)
	// setHelpScoutAppIds(helpScoutAppIds)
	setApiKeyStore({ GOOGLE_MAPS_SDK: googleMapsSdkApiKey, STRIPE_PUBLISHABLE_KEY: stripePublishableKey })
	const csrfToken = getCsrfToken()
	initializeI18n(locale)
	// Our Cookie consent right now isn't GDPR compatible, so there is not point in showing it. Also, Chrome will soon not allow third party cookies anyways, and iPhone will stop showing "distracting" elements such as cookie popups
	// initializeCookieConsent(locale)
	const client = createClient({
		url: `${railsContext.origin}/${locale}/graphql`,
		fetchOptions: () => {
			return {
				headers: { 'X-CSRF-Token': csrfToken, affiliate }, // I'm pretty sure these needs to be camelcase, otherwise I can't access them in rails for some reason
			}
		},
		exchanges: [cacheExchange, fetchExchange],
	})
	if (!isServerRendering() && !window.filterCache) {
		window.filterCache = {} // Initialize the filter cache
	}
	useEffect(() => {
		// Clean up any old params
		// OBS: IF CHANGING THIS ALSO CHANGE PARAMS IN unnecessary_params_redirects
		const url = new URL(location.href)
		if (url.searchParams.get('modal')) {
			url.searchParams.delete('modal')
		}
		if (url.searchParams.get('cs')) {
			url.searchParams.delete('cs')
		}
		window.history.replaceState({}, document.title, url.href)
	}, [])
	setAdminDestination(adminDestination)
	return (
		<Provider value={client}>
			<ScreenSizeProvider railsContext={railsContext}>
				<UserProvider
					user={user}
					adminDestination={adminDestination}
					rootPath={routes.static.rootDomainStartPage[isRootDomain ? 'path' : 'url']}
				>
					<GroupInquiryProvider>{children}</GroupInquiryProvider>
				</UserProvider>
			</ScreenSizeProvider>
		</Provider>
	)
}

export default App
