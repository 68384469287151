import React, { useReducer } from 'react'
import ContentSideBarContext from './ContentSideBarContext'
import { callHotjar } from '../../utils/applicationHelper'

// contentType: 'HOST' or 'COURSE'
// content: The content to open up in the side bar (i.e. host or course)
// skipUrlManipulation: Use this to avoid automatically changing the URL to the course's URL when opening it. Used on EditCoursePage for example, to prevent the host from accidentally leaving the EditCoursePage and going to the course page when going back and forth with the browser's navigation buttons after having opened a course in the content side bar
function reducer(state, { type, data }) {
	if (data === undefined) return state
	switch (type) {
		case 'NEW_CONTENT':
			if (!data.skipUrlManipulation && !state.skipUrlManipulation) {
				// Change the URL in the browser so if the user refreshes the page they will land on the content's landing page.
				const url = new URL(data.content.url)
				// Append content sidebar = true to make the url different from the canonical, so Google don't think this is the course's canonical content (since other html i still present behind the side bar)
				url.searchParams.set('cs', 't')
				if (state.content && state.sideBarIsOpen) {
					// The ContentSideBar is already opened, thus the URL is already changed in the browser. This happens e.g. when clicking a course card
					// on the opened ContentSideBar. Replace that URL with the new content's URL instead of adding a new URL to the history.
					window.history.replaceState({}, document.title, url.href)
				} else {
					// No ContentSideBar is opened so add the content's URL to the history and display it in the browser's URL field.
					window.history.pushState({}, document.title, url.href)
				}
			}
			if (data.contentType === 'COURSE') {
				callHotjar('opened_workshop_page_overlay')
			} else if (data.contentType === 'HOST') {
				callHotjar('opened_host_page_overlay')
			}
			return {
				...state,
				...data,
				sideBarIsOpen: true,
			}
		case 'HIDE_CONTENT':
			if (!state.skipUrlManipulation) {
				// Switch back from the content's url to the previous url including its filter parameters since the side bar was closed
				// When the user presses the back button in the browser however, we don't need to call back() since the browser has already done it
				if (data.goBackInHistory) window.history.back()
			} else {
				// Reset the state's skipUrlManipulation after closing the content side bar
				state.skipUrlManipulation = false
			}
			return {
				...state,
				sideBarIsOpen: false,
			}
		default:
			throw new Error()
	}
}

export default ({ children }) => {
	const [state, dispatch] = useReducer(reducer, { content: null, contentType: null })
	return <ContentSideBarContext.Provider value={{ state, dispatch }}>{children}</ContentSideBarContext.Provider>
}
