import React, { useContext, useEffect } from 'react'
import axios from 'axios'
import UserContext from '../../../../contexts/user/UserContext'
// import { beaconCaller, getHelpScoutAppIds } from '../../../../utils/helpScoutHelper'
import { useTranslation } from 'react-i18next-new'
import { userProviderInitialState } from '../../../../contexts/user/UserProvider'
import useRoutes from '../../../../hooks/useRoutes'

import EjblaLogo from '../../svg_icons/EjblaLogo'
import { CenteredSection, SmallPageSectionDivider } from '../../layout/PageComponents'
import LoginContent from './LoginContent'
import SignUpContent from './SignUpContent'
import SignUpWithEmailContent from './SignUpWithEmailContent'
import ConfirmEmailContent from './ConfirmEmailContent'
import ForgotPasswordContent from './ForgotPasswordContent'
import FavouriteContent from './FavouriteContent'
import DynamicModal from '../DynamicModal'
import getHostBranding from '../../../../hooks/getHostBranding'
import { getCloudinaryImageUrl } from '../../../../utils/imageHelper'
import { HostBrandingIconWrapper, HostBrandingTitleWrapper } from '../../../layout/header/Header'
import {
	getAdminDestination,
	isHostSignupDomain,
	isRootDomain,
	upgradeTierLink,
} from '../../../../utils/applicationHelper'
import LoadingSpinner from '../../other/LoadingSpinner'
import styled from 'styled-components'
import HostSignupLogo from '../../svg_icons/HostSignupLogo'

// An already existing host logged in
export const initiateHostOnboarding = publishedPastCourseDatesCount => {
	if (publishedPastCourseDatesCount) {
		location = useRoutes().admin.host.overview.url
	} else {
		location = useRoutes().admin.host.progress.url
	}
}

const SessionModal = ({ hostBranded }) => {
	const { userState, dispatch } = useContext(UserContext)
	const { t, i18n } = useTranslation()
	const routes = useRoutes()
	const { displayState, user, initiateOnboarding, forceOpen, callback, from, tier, tierPayInterval, isHost, pwa } =
		userState
	const adminDestination = getAdminDestination()
	useEffect(() => {
		if (displayState && forceOpen && !user.signedIn && !adminDestination) {
			document.getElementById('HEADER').style.filter = 'blur(3px)'
			document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'blur(3px)'
		}
	}, [forceOpen])
	if (user.signedIn) return null // Don't open it by accident if they are already logged in

	let closeModal = () =>
		forceOpen ||
		dispatch({
			type: 'SESSION_MODAL_DISPLAY_STATE',
			data: userProviderInitialState,
		})
	let onLogin = (user, hostWantsToUpgradeTier, hostAccountWasCreated, initiateOnboarding) => {
		/*const finishLogIn = () => {
			const helpScoutAppIds = getHelpScoutAppIds()
			const currentAppId = Beacon('info').beaconId
			if (user.isUser && currentAppId !== helpScoutAppIds.user) {
				// Current user is a regular user, but the loaded Help Scout App is our host app, so load the user app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.user)
			} else if ((user.isHost || user.isSuperAdmin) && currentAppId !== helpScoutAppIds.host) {
				// Current user is a host, but the loaded Help Scout App is our regular user app, so load the host app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.host)
			}
			// 'identify' starts a session in Help Scout for the user, so they don't need to enter their email and name when interacting with it
			Beacon('identify', { name: user.fullName || '', email: user.email || '' })
		}
		// beaconCaller(finishLogIn)
		// finishLogIn()*/
		if (adminDestination) {
			window.location = adminDestination
		} else {
			if (forceOpen) {
				document.getElementById('HEADER').style.filter = 'none'
				document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'none'
			}
			const callbackOptions = {}
			if (user.isHost) {
				// Host logged in
				if (hostWantsToUpgradeTier) {
					// The host logged in and wants to upgrade tier, so reroute them to the upgrade tier section in host admin
					window.location = upgradeTierLink(tier)
				} else if (hostAccountWasCreated) {
					// A host account was created
					initiateHostOnboarding()
				} else {
					// An already existing host logged in normally
					if (initiateOnboarding) {
						initiateHostOnboarding(user.publishedPastCourseDatesCount)
					} else {
						dispatch({ type: 'SET_USER', data: user })
					}
				}
			} else if (user.isSuperAdmin) {
				// SA logged in
				window.location = routes.superAdmin.dashboard.url
			} else {
				// User logged in
				if (initiateOnboarding) {
					window.location = routes.user.onboarding.url
				} else {
					dispatch({ type: 'SET_USER', data: user })
				}
			}
			if (callback) {
				callback(user, callbackOptions)
			}
		}
	}

	const authRequest = (response, onSubmitFinished, provider) => {
		const url = new URL(useRoutes().oauth[provider].url)
		if (initiateOnboarding) url.searchParams.set('initiate_onboarding', 'true')
		if (tier) url.searchParams.set('tier', tier)
		if (tierPayInterval) url.searchParams.set('tier_pay_interval', tierPayInterval)
		if (isHost) url.searchParams.set('is_host', 'true')
		axios({
			method: 'post',
			url: url,
			data: response,
		})
			.then(response => {
				const {
					user,
					confirmedEmail,
					emailToBeConfirmed,
					confirmEmailAuthenticityToken,
					initiateOnboarding,
					hostWantsToUpgradeTier,
					hostAccountWasCreated,
				} = response.data
				if (!confirmedEmail) {
					dispatch({
						type: 'SESSION_MODAL_DISPLAY_STATE',
						data: {
							displayState: 'CONFIRM_EMAIL',
							emailToBeConfirmed,
							confirmEmailAuthenticityToken,
							initiateOnboarding,
							hostWantsToUpgradeTier,
							hostAccountWasCreated,
						},
					})
				} else {
					onLogin(user, hostWantsToUpgradeTier, hostAccountWasCreated, initiateOnboarding)
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	const socialMediaButtonProps = {
		i18n,
		t,
		authRequest,
	}
	const childProps = {
		dispatch,
		t,
		onLogin,
		socialMediaButtonProps,
		...userState,
	}
	const hostBranding = getHostBranding()
	return (
		<>
			<DynamicModal
				displayModal={!!displayState}
				setDisplayModal={closeModal}
				hideCloseButton={forceOpen}
				zIndex={9000}
			>
				<CenteredSection>
					{isHostSignupDomain() && <HostSignupLogo onClick={closeModal} disableClick={forceOpen} />}
					{isRootDomain() && (
						<>
							{hostBranding.active && hostBranded ? (
								<>
									{hostBranding.icon ? (
										<HostBrandingIconWrapper src={getCloudinaryImageUrl(hostBranding.icon, 200)} />
									) : (
										<HostBrandingTitleWrapper>{hostBranding.title}</HostBrandingTitleWrapper>
									)}
								</>
							) : (
								<EjblaLogo onClick={closeModal} disableClick={forceOpen} />
							)}
						</>
					)}
				</CenteredSection>
				<SmallPageSectionDivider />
				{displayState === 'LOG_IN' && <LoginContent {...childProps} />}
				{displayState === 'SIGN_UP' && <SignUpContent {...childProps} />}
				{displayState === 'SIGN_UP_WITH_EMAIL' && <SignUpWithEmailContent {...childProps} />}
				{displayState === 'CONFIRM_EMAIL' && <ConfirmEmailContent {...childProps} />}
				{displayState === 'FORGOT_PASSWORD' && <ForgotPasswordContent {...childProps} />}
				{displayState === 'FAVOURITE' && <FavouriteContent {...childProps} />}
			</DynamicModal>
			{!!getAdminDestination() && (
				<LoadingSpinnerWrapper>
					<LoadingSpinner />
				</LoadingSpinnerWrapper>
			)}
		</>
	)
}

// Loading spinner for desktop when admin destination is present, since the OverLay modal can't SSR and can take a second to load, especially on bad network
const LoadingSpinnerWrapper = styled.div`
	position: fixed;
	left: 50%;
	top: 300px;
	transform: translateX(-50%);
	z-index: 1;
	opacity: 0.3;
`

export default SessionModal
