import { Trans } from 'react-i18next-new'
import axios from 'axios'
import Form from '../../forms/Form'
import TextInput from '../../forms/TextInput'
import CheckboxInput from '../../forms/CheckboxInput'
import { UnderlinedA } from '../../Typography'
import { CenteredSection, RepulsiveRow, SmallPageSection } from '../../layout/PageComponents'
import Button from '../../buttons/Button'
import React from 'react'
import useRoutes from '../../../../hooks/useRoutes'
import CrocodileUp from '../../svg_icons/CrocodileUp'
import CrocodileDown from '../../svg_icons/CrocodileDown'
import ToggleableElement from '../../other/ToggleableElement'
import getHostBranding from '../../../../hooks/getHostBranding'
import PasswordInput from '../../forms/PasswordInput'
import TextAreaInput from '../../forms/TextAreaInput'

const SignUpWithEmailContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const { initiateOnboarding, showPhoneInput, tier, tierPayInterval, pwa, isHost } = contextData
	const routes = useRoutes()
	const handleSubmit = (inputValues, onSubmitFinished) => {
		const { email, confirmEmail, password, firstName, lastName, phone, newsletter, referral, signupPurpose } =
			inputValues
		if (email !== confirmEmail) {
			onSubmitFinished(t('common:errormessages.confirmEmailFail'))
			return
		}
		axios({
			method: 'post',
			url: routes.signup.create.url,
			data: {
				user: {
					email,
					password,
					first_name: firstName,
					last_name: lastName,
					phone,
					newsletter,
					signup_purpose: signupPurpose,
				},
				used_signup_referral_code: referral,
				tier,
				tier_pay_interval: tierPayInterval,
				is_host: isHost,
				initiate_onboarding: initiateOnboarding,
			},
		})
			.then(response => {
				if (response.data.success === true) {
					onLogin(response.data.user, false, response.data.hostAccountWasCreated, response.data.initiateOnboarding)
				} else {
					onSubmitFinished(t('common:errormessages.generic'))
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	return (
		<>
			<Form onSubmit={handleSubmit}>
				<TextInput name="firstName" required />
				<TextInput name="lastName" required />
				{(showPhoneInput || isHost) && <TextInput type="tel" name="phone" required />}
				<TextInput type="email" name="email" required />
				<TextInput type="email" name="confirmEmail" required />
				<PasswordInput name="password" required />
				{isHost && <TextAreaInput name="signupPurpose" required />}
				<CheckboxInput
					name="acceptPolicies"
					required
					label={
						<Trans i18nKey={'common:forms:acceptPolicies'} values={{ val: 'useragreement' }}>
							I accept the
							<UnderlinedA target="_blank" rel="noopener" href={routes.custom.termsOfUse.url}>
								terms of use
							</UnderlinedA>
						</Trans>
					}
				/>
				{!getHostBranding().active && !isHost && <CheckboxInput name="newsletter" />}
				{isHost && (
					<ToggleableElement
						skipPadding
						toggler={toggled => (
							<RepulsiveRow>
								<p>{t('copy:sessionModal.referral')}</p>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</RepulsiveRow>
						)}
					>
						<TextInput name="referral" />
					</ToggleableElement>
				)}
				<SmallPageSection>
					<Button buttonType="RECTANGLE" wide>
						{t('copy:sessionModal.signUp')}
					</Button>
				</SmallPageSection>
				<CenteredSection>
					<p>
						{t('copy:sessionModal.alreadyHaveAccount')}&nbsp;
						<UnderlinedA
							onClick={() =>
								dispatch({
									type: 'SESSION_MODAL_DISPLAY_STATE',
									data: {
										displayState: 'LOG_IN',
										callback: null,
										title: null,
										message: null,
										tier: null,
									},
								})
							}
						>
							{t('copy:sessionModal.signIn')}
						</UnderlinedA>
					</p>
				</CenteredSection>
			</Form>
		</>
	)
}

export default SignUpWithEmailContent
