import React, { useContext } from 'react'
import styled from 'styled-components'
import { theme } from '../../../../utils/theme'
import {
	CenteredSection,
	CenteredSmallPageSection,
	LeftAlignedFlexBox,
	SmallPaddingWrapper,
	SmallPageSection,
	SmallPageSectionRepulsiveRow,
	XXSmallPageSection,
} from '../../../reusable_components/layout/PageComponents'
import RadioButtonChecked from '../../../reusable_components/svg_icons/RadioButtonChecked'
import RadioButton from '../../../reusable_components/svg_icons/RadioButton'
import { B1, Markdown, P2, P5 } from '../../../reusable_components/Typography'
import UserContext from '../../../../contexts/user/UserContext'
import useRoutes from '../../../../hooks/useRoutes'
import { getTiers } from '../../../../utils/applicationHelper'
import { ChoosePricingPeriod, PopularBadge, PricingFeatures } from '../../../static_pages/PricingPage'

const TierWrapper = styled(SmallPaddingWrapper)`
	text-align: left; // Keep this if the modal is in a CenteredSection
	border-radius: 5px;
	cursor: ${({ isSelected }) => (isSelected ? 'auto' : 'pointer')};
	border: 1px solid ${({ isSelected }) => (isSelected ? theme.colors.black : theme.colors.gray)};
	pointer-events: ${({ isCurrentHostTier }) => (isCurrentHostTier ? 'none' : 'auto')};
	* {
		color: ${({ isCurrentHostTier }) => (isCurrentHostTier ? theme.colors.lightTextColor : theme.colors.textColor)};
	}
	position: relative;
`

const UpgradeTier = props => {
	let { t, setSelectedTier, selectedTier, title, hostTier, hideReadMoreLink, payInterval, setPayInterval } = props
	const routes = useRoutes()
	const { userState, dispatch } = useContext(UserContext)
	// These are not set when used in the SessionModal, so there we update it UserContext instead so the SessionModal has access to it
	if (!selectedTier) selectedTier = userState.tier
	if (!payInterval) payInterval = userState.tierPayInterval
	if (!setSelectedTier)
		setSelectedTier = newTier => dispatch({ type: 'SESSION_MODAL_DISPLAY_STATE', data: { tier: newTier } })
	if (!setPayInterval)
		setPayInterval = newPayInterval =>
			dispatch({ type: 'SESSION_MODAL_DISPLAY_STATE', data: { tierPayInterval: newPayInterval } })
	const tierBoxProps = { selectedTier, setSelectedTier, t, hostTier, payInterval }

	return (
		<>
			{title && (
				<>
					<CenteredSection>
						<P2>{title}</P2>
					</CenteredSection>
					<br />
					<br />
				</>
			)}
			<SmallPageSection>
				<CenteredSection>
					<ChoosePricingPeriod t={t} setPayInterval={setPayInterval} payInterval={payInterval} />
				</CenteredSection>
			</SmallPageSection>
			{/* Ony show TierBoxes for tiers above the current tier, i.e the ones the host can upgrade to */}
			{(!hostTier || ['no_tier', 'free'].includes(hostTier)) && (
				<>
					<TierBox {...tierBoxProps} tier="free" />
					<br />
				</>
			)}
			{(!hostTier || ['no_tier', 'free', 'standard'].includes(hostTier)) && (
				<>
					<TierBox {...tierBoxProps} tier="standard" />
					<br />
				</>
			)}
			<TierBox {...tierBoxProps} tier="pro" />
			<CenteredSmallPageSection>
				<P5>{t('admin:upgradeTier.transactionFee')}</P5>
			</CenteredSmallPageSection>
			{hideReadMoreLink || (
				<CenteredSmallPageSection>
					<Markdown linkTarget="_blank">{t('admin:upgradeTier.readMore', { url: routes.static.pricing.url })}</Markdown>
				</CenteredSmallPageSection>
			)}
		</>
	)
}

const TierBox = props => {
	const { selectedTier, setSelectedTier, t, tier, hostTier, payInterval } = props
	const isSelected = selectedTier === tier
	const isCurrentHostTier = tier === hostTier
	const tierData = getTiers()[tier]
	return (
		<TierWrapper isCurrentHostTier={isCurrentHostTier} isSelected={isSelected} onClick={() => setSelectedTier(tier)}>
			{tier === 'standard' && <PopularBadge>{t('admin:upgradeTier.popular')}</PopularBadge>}
			<P2>{t('admin:upgradeTier.name.' + tier)}</P2>
			<SmallPageSectionRepulsiveRow>
				<LeftAlignedFlexBox alignItems="flex-end" innerMargin={theme.margins.XXSmall}>
					<B1>{tierData.monthlyFee[payInterval]}</B1>
					<p>{t('admin:upgradeTier.pricePerMonth')}</p>
				</LeftAlignedFlexBox>
				{isSelected ? <RadioButtonChecked /> : <RadioButton />}
			</SmallPageSectionRepulsiveRow>
			<XXSmallPageSection>
				<p>{t(`admin:upgradeTier.${payInterval}Paid`)}</p>
			</XXSmallPageSection>
			<p>
				{t(`admin:upgradeTier.bookFee`)}&nbsp;{tier === 'free' ? 3.9 : tierData.bookingFee}%
			</p>
			<SmallPageSection />
			<p>{t('admin:upgradeTier.description.' + tier)}</p>
			<SmallPageSection />
			<PricingFeatures t={t} tier={tier} featureQuantity={tierData.featureQuantity} />
		</TierWrapper>
	)
}

export default UpgradeTier
