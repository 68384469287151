import React, { useContext } from 'react'

import { RepulsiveRow } from '../../layout/PageComponents'
import Close from '../../svg_icons/Close'
import SideBar from './SideBar'
import ContentSideBarContext from '../../../../contexts/content_side_bar/ContentSideBarContext'
import CourseContent from '../../../courses/show/CourseContent'
import HostContent from '../../../host_pages/show/HostContent'
import ShareButton from '../../buttons/ShareButton'
import HelmetHead from '../../../layout/HelmetHead'
import { theme } from '../../../../utils/theme'

const ContentSideBar = ({ t }) => {
	const { state, dispatch } = useContext(ContentSideBarContext)
	const { content, contentType } = state
	let closeSideBar = goBackInHistory => {
		dispatch({ type: 'HIDE_CONTENT', data: { goBackInHistory } })
	}
	const sideBarIsOpen = state.sideBarIsOpen
	const sideBarHeader = content ? (
		<div
			style={{
				position: 'absolute',
				top: theme.paddings.small,
				left: 0,
				right: 0,
				zIndex: 2,
				paddingLeft: theme.margins.small,
				paddingRight: theme.margins.small,
			}}
		>
			<RepulsiveRow>
				<ShareButton
					fill="white"
					title={contentType === 'COURSE' ? content.title : content.fullName}
					text={t('shared:share.text', { object: contentType === 'COURSE' ? content.title : content.fullName })}
				/>
				<Close fill="white" onClick={closeSideBar} />
			</RepulsiveRow>
		</div>
	) : null
	return (
		<SideBar
			color="WHITE"
			position="RIGHT"
			isOpen={sideBarIsOpen}
			handleClose={closeSideBar}
			maxWidth={1100}
			displayScrollbar={true}
		>
			{contentType === 'COURSE' ? (
				<>
					{sideBarIsOpen && (
						<HelmetHead
							title={content.ogTitle}
							description={content.summary}
							image={content.ogImage}
							defaultLocalizedUrls={false}
							localizedUrls={content.localizedUrls}
							seoIndexed={content.seoIndexed}
							canonical={content.canonical}
						/>
					)}
					<CourseContent
						course={content}
						lazyLoadCourseCards={false}
						t={t}
						key={content?.id}
						sideBarHeader={sideBarHeader}
						courseSideBarIsOpen={sideBarIsOpen}
						closeSideBar={closeSideBar}
					/>
				</>
			) : (
				<>
					{sideBarIsOpen && (
						<HelmetHead
							title={content.ogTitle}
							description={content.ogDescription}
							image={content.ogImage}
							seoIndexed={content.seoIndexed}
						/>
					)}
					<HostContent
						host={content}
						key={content?.id}
						sideBarHeader={sideBarHeader}
						hostPageSideBarIsOpen={sideBarIsOpen}
					/>
				</>
			)}
		</SideBar>
	)
}

export default ContentSideBar
