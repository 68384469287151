import React, { useState } from 'react'
import { theme } from '../../utils/theme'

import {
	CenteredSection,
	DynamicPageSection,
	DynamicPageSectionRow,
	LeftAlignedFlexBox,
	PageSection,
	PageSidePaddingWrapper,
	PageTopSection,
	SmallPageSection,
	XSmallPageSection,
	XXSmallPageSection,
} from '../reusable_components/layout/PageComponents'
import { B1, B2, Markdown, P2 } from '../reusable_components/Typography'
import styled from 'styled-components'
import GetStartedButton from './ejbla_pro_page/GetStartedButton'
import CheckMark from '../reusable_components/svg_icons/CheckMark'
import Breakpoint from '../reusable_components/responsive/Breakpoint'
import ReusableSlider, { SlideWrapper } from '../reusable_components/other/ReusableSlider'
import ScreenSizeContext from '../../contexts/screen_size/ScreenSizeContext'
import Button from '../reusable_components/buttons/Button'
import { upgradeTierLink } from '../../utils/applicationHelper'
import BookDemoButton from './ejbla_pro_page/BookDemoButton'

const PricingPlanWrapper = styled.div`
	border: 2px solid ${({ border }) => border};
	border-radius: 10px;
	padding: ${theme.paddings.small};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	> div:nth-child(2) {
		margin-bottom: ${theme.margins.large};
	}
	position: relative;
`
const FeatureWrapper = styled.div`
	background: ${({ background }) => background};
	padding: ${theme.paddings.XSmall};
	> div > div:not(:first-child) {
		text-align: center;
	}
`
const SliderWrapper = styled.div`
	${SlideWrapper} {
		padding-top: ${theme.paddings.large};
	}
`
export const PopularBadge = styled.div`
	background: ${theme.colors.black};
	color: white !important;
	padding: 3px ${theme.paddings.XSmall};
	border-radius: 20px;
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translateX(-50%);
`

const PricingPlan = ({ t, tier, user, tierData, payInterval, setPayInterval }) => {
	let border, bookDemobutton
	if (tier === 'free') {
		border = theme.colors.gray
		bookDemobutton = false
	} else if (tier === 'standard') {
		border = theme.colors.black
		bookDemobutton = true
	} else if (tier === 'pro') {
		border = theme.colors.gray
		bookDemobutton = true
	}
	return (
		<PricingPlanWrapper border={border}>
			{tier === 'standard' ? (
				<PopularBadge>{t('admin:upgradeTier.popular')}</PopularBadge>
			) : (
				<div style={{ display: 'none' }} />
			)}
			<div>
				<B2>{t('admin:upgradeTier.name.' + tier)}</B2>
				<SmallPageSection />
				<LeftAlignedFlexBox alignItems="flex-end" innerMargin={theme.margins.XXSmall}>
					<B1>{tierData.monthlyFee[payInterval]}</B1>
					<p>{t('admin:upgradeTier.pricePerMonth')}</p>
				</LeftAlignedFlexBox>
				<XXSmallPageSection>
					<p>{t(`admin:upgradeTier.${payInterval}Paid`)}</p>
				</XXSmallPageSection>
				<p>
					{t(`admin:upgradeTier.bookFee`)}&nbsp;{tier === 'free' ? 3.9 : tierData.bookingFee}%
				</p>
				<SmallPageSection />
				<p>{t('admin:upgradeTier.description.' + tier)}</p>
				<SmallPageSection />
				<PricingFeatures t={t} tier={tier} featureQuantity={tierData.featureQuantity} />
			</div>
			<div>
				{user.isHost ? (
					<Button
						onClick={() => {
							window.location = upgradeTierLink(tier, payInterval)
						}}
						disable={tierData.rank <= user.tierRank}
						buttonType="RECTANGLE"
						wide
					>
						{t('common:buttons.choose')}
					</Button>
				) : (
					<GetStartedButton
						primary={tier === 'free'}
						tier={tier}
						buttonType="RECTANGLE"
						wide
						payInterval={payInterval}
						setPayInterval={setPayInterval}
					>
						{t('common:buttons.choose')}
					</GetStartedButton>
				)}
				<SmallPageSection />
				<div style={{ opacity: bookDemobutton ? 1 : 0, pointerEvents: bookDemobutton ? 'auto' : 'none' }}>
					<BookDemoButton t={t} color="OUTLINED_BLACK" buttonType="RECTANGLE" wide />
				</div>
			</div>
		</PricingPlanWrapper>
	)
}
const PricingHeader = ({ t, tier }) => {
	return tier ? (
		<div>
			<P2 style={{ textAlign: 'center' }}>{t('admin:upgradeTier.name.' + tier)}</P2>
		</div>
	) : (
		<div />
	)
}
const FeatureCategory = ({ name }) => {
	return (
		<FeatureWrapper background={theme.colors.gray}>
			<DynamicPageSectionRow
				margins={{
					all: 0,
				}}
				childrenPerRowDesktop={4}
				childrenPerRowMobile={4}
			>
				<div>
					<P2>{name}</P2>
				</div>
				<div></div>
				<div></div>
				<div></div>
			</DynamicPageSectionRow>
		</FeatureWrapper>
	)
}
const Feature = ({ name, includesArray, index }) => {
	return (
		<FeatureWrapper background={theme.colors[index % 2 === 1 ? 'lightGray' : 'white']}>
			<DynamicPageSectionRow
				margins={{
					all: 0,
					inner: theme.margins.XXSmallInt,
				}}
				childrenPerRowDesktop={4}
				childrenPerRowMobile={4}
			>
				<div>
					<Markdown>{name}</Markdown>
				</div>
				<div>{includesArray[0] ? Number.isInteger(includesArray[0]) ? <CheckMark /> : includesArray[0] : ''}</div>
				<div>{includesArray[1] ? Number.isInteger(includesArray[1]) ? <CheckMark /> : includesArray[1] : ''}</div>
				<div>{includesArray[2] ? Number.isInteger(includesArray[2]) ? <CheckMark /> : includesArray[2] : ''}</div>
			</DynamicPageSectionRow>
		</FeatureWrapper>
	)
}

const PricingPage = ({ t, user, tiers }) => {
	const [payInterval, setPayInterval] = useState('yearly')
	const featureArray = [
		[
			[1, 1, 1],
			[1, 1, 1],
			[0, 0, 1],
			[0, 0, 1],
		],
		[
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[0, 1, 1],
		],
		[
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
		],
		[
			[1, 1, 1],
			[1, 1, 1],
			[0, 0, 1],
		],
		[
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[0, 1, 1],
		],
		[
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[1, 1, 1],
			[0, 1, 1],
		],
		[
			[0, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
			[0, 1, 1],
			[0, 0, 1],
			['100', '150', '300'],
		],
	]
	return (
		<>
			<PageSidePaddingWrapper>
				<PageTopSection>
					<h1>{t('copy:pricingPage.pageTitle')}</h1>
					<XXSmallPageSection />
					<p>{t('copy:pricingPage.pageSubTitle')}</p>
					<XSmallPageSection />
					<ChoosePricingPeriod t={t} payInterval={payInterval} setPayInterval={setPayInterval} />
				</PageTopSection>
			</PageSidePaddingWrapper>
			<PageSection>
				<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
					<SliderWrapper>
						<ReusableSlider>
							{['free', 'standard', 'pro'].map((tier, key) => (
								<PricingPlan
									key={key}
									tier={tier}
									t={t}
									user={user}
									tierData={tiers[tier]}
									payInterval={payInterval}
									setPayInterval={setPayInterval}
								/>
							))}
						</ReusableSlider>
					</SliderWrapper>
					<SmallPageSection />
				</Breakpoint>
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<PageSidePaddingWrapper>
						<DynamicPageSectionRow
							margins={{
								all: theme.margins.smallInt,
							}}
							childrenPerRowDesktop={3}
							alignItems="stretch"
						>
							{['free', 'standard', 'pro'].map((tier, key) => (
								<PricingPlan
									key={key}
									tier={tier}
									t={t}
									user={user}
									tierData={tiers[tier]}
									payInterval={payInterval}
									setPayInterval={setPayInterval}
								/>
							))}
						</DynamicPageSectionRow>
					</PageSidePaddingWrapper>
				</Breakpoint>
				<PageSidePaddingWrapper>
					<CenteredSection>
						<p>{t('admin:upgradeTier.transactionFee')}</p>
					</CenteredSection>
				</PageSidePaddingWrapper>
			</PageSection>
			<ScreenSizeContext.Consumer>
				{({ screenType }) => (
					<PageSidePaddingWrapper skipSidePadding={['phone', 'tablet'].includes(screenType)}>
						<FeatureWrapper>
							<DynamicPageSectionRow
								margins={{
									all: theme.margins.XSmallInt,
								}}
								childrenPerRowDesktop={4}
								childrenPerRowMobile={4}
							>
								{['', 'free', 'standard', 'pro'].map((tier, key) => (
									<PricingHeader key={key} tier={tier} t={t} />
								))}
							</DynamicPageSectionRow>
						</FeatureWrapper>
						{featureArray.map((features, categoryKey) => (
							<DynamicPageSection margins={{ bottom: theme.margins.largeInt }} key={categoryKey}>
								<FeatureCategory name={t(`copy:pricingPage.featureCategory${categoryKey}`)} />
								{features.map((includesArray, featureKey) => (
									<Feature
										name={t(`copy:pricingPage.featureCategory${categoryKey}feature${featureKey}`)}
										includesArray={includesArray}
										key={featureKey}
										index={featureKey}
									/>
								))}
							</DynamicPageSection>
						))}
					</PageSidePaddingWrapper>
				)}
			</ScreenSizeContext.Consumer>
			<CenteredSection>
				<Markdown linkTarget="_blank">{t('copy:pricingPage.readMore')}</Markdown>
			</CenteredSection>
		</>
	)
}

const StyledPricingPeriods = styled.div`
	border-radius: 5px;
	border: 2px solid ${theme.colors.gray};
	display: flex;
`
export const ChoosePricingPeriod = ({ t, payInterval, setPayInterval }) => {
	return (
		<StyledPricingPeriods>
			<PricingPeriod t={t} setPayInterval={setPayInterval} payInterval="yearly" currentPayInterval={payInterval} />
			<PricingPeriod t={t} setPayInterval={setPayInterval} payInterval="quarterly" currentPayInterval={payInterval} />
		</StyledPricingPeriods>
	)
}
const StyledPricingPeriod = styled.div`
	background: ${({ chosen }) => (chosen ? theme.colors.gray : theme.colors.white)};
	font-weight: ${({ chosen }) => (chosen ? 'bold' : 'normal')};
	transition: background-color 0.3s;
	padding: ${theme.paddings.XSmall} ${theme.paddings.small};
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
`
const PricingPeriod = ({ t, setPayInterval, payInterval, currentPayInterval }) => {
	return (
		<StyledPricingPeriod chosen={currentPayInterval === payInterval} onClick={() => setPayInterval(payInterval)}>
			{t(`admin:upgradeTier.${payInterval}`)}
		</StyledPricingPeriod>
	)
}
export const PricingFeatures = ({ t, featureQuantity, tier }) => {
	return [...Array(featureQuantity).keys()].map((feature, key) => (
		<React.Fragment key={key}>
			<LeftAlignedFlexBox alignItems="flex-start">
				<CheckMark width="20px" height="20px" />
				<p>{t(`admin:upgradeTier.featureSummary.${tier}.${feature}`)}</p>
			</LeftAlignedFlexBox>
			<XXSmallPageSection />
		</React.Fragment>
	))
}

export default PricingPage
