import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SmallPageSection } from '../layout/PageComponents'
import { CheckboxLabel } from './CheckboxInput'
import { InputWrapper } from './TimeInput'
import EyeHidden from '../svg_icons/EyeHidden'
import { FormLabel, standardInputStyling } from './TextInput'
import Eye from '../svg_icons/Eye'

const FieldInput = styled.input`
	${({ invalidFormat }) => standardInputStyling(invalidFormat)}
`
// Only update when props update using memoization for performance
const PasswordInput = React.memo(
	React.forwardRef(function textInput(
		{
			label,
			labelElement,
			skipLabel,
			required,
			skipRequiredAsterisk,
			name,
			value,
			hidden,
			tooltip,
			t,
			nonWhiteBackground,
			...props
		},
		ref,
	) {
		useEffect(() => {
			if (name && props.parentTogglers && props.setParentTogglers) props.setParentTogglers(name, props.parentTogglers)
		}, [])
		const [showPassword, setShowPassword] = useState(false)
		return (
			<SmallPageSection style={{ display: hidden ? 'none' : 'block' }} id={name + 'Parent'}>
				{!skipLabel && label && (
					<FormLabel>
						<div>
							{label} {required && !skipRequiredAsterisk && '*'}
						</div>
					</FormLabel>
				)}
				{!skipLabel && labelElement && (
					<CheckboxLabel htmlFor={name}>
						{labelElement} {required && !skipRequiredAsterisk && '*'}
					</CheckboxLabel>
				)}
				<InputWrapper nonWhiteBackground={nonWhiteBackground}>
					<FieldInput
						id={name}
						ref={ref}
						required={required}
						name={name}
						value={typeof value === 'undefined' ? '' : value}
						type={showPassword ? 'text' : 'password'}
						data-type={showPassword ? 'text' : 'password'} // Some browsers don't support all type attributes, so we access this info from a data attribute instead as a fallback
						{...props}
					/>
					{showPassword ? (
						<Eye width={18} height={18} onClick={() => setShowPassword(false)} />
					) : (
						<EyeHidden width={18} height={18} onClick={() => setShowPassword(true)} />
					)}
				</InputWrapper>
			</SmallPageSection>
		)
	}),
)

PasswordInput.propTypes = {
	label: PropTypes.string,
	tooltip: PropTypes.string, // Add a tooltip text
	labelElement: PropTypes.object, // A html element can be chosen instead of a text label
	skipLabel: PropTypes.bool, // Don't show a label
	name: PropTypes.string.isRequired, // Main identifier. Needs to be unique for the form
	desiredName: PropTypes.string, // Set to e.g. "firstName" if another input with "firstName" already exists
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	hidden: PropTypes.bool, // Hide it instead of removing it from the DOM, to save the user input
	invalidFormat: PropTypes.bool,
}

export default PasswordInput
